import React, { FunctionComponent } from "react"
import dayjs from 'dayjs'
import kebabCase from "lodash/kebabCase"
import { Link } from "gatsby"
import advancedFormat from 'dayjs/plugin/advancedFormat'

interface Props {
  authorName: string
  publicationDate: Date
  readingTime?: number
}

const ArticleMetadata: FunctionComponent<Props> = ({
  authorName,
  publicationDate,
  readingTime,
}) => {
  dayjs.extend(advancedFormat)
  const dateLabel = dayjs(publicationDate).format("Do MMM YYYY")
  const authorLink = `/blog/authors/${kebabCase(authorName)}`

  return (
    <dl className="inline-flex flex-wrap text-1 xxl:text-2">
      <dt className="sr-only">Publication date</dt>
      <dd className="mb-2">
        <time dateTime={publicationDate.toISOString()}>{dateLabel}</time>
      </dd>

      <dt className="sr-only">Author</dt>
      <dd className="mb-2 ml-4"><Link to={authorLink}>{authorName}</Link></dd>

      {readingTime && (
        <>
          <dt className="sr-only">Reading time</dt>
          <dd className="mb-2 ml-4">{readingTime} minute read</dd>
        </>
      )}
    </dl>
  )
}

export default ArticleMetadata
